import styled from 'styled-components'

export const Container = styled.div`
  padding: 15px;
  min-height: 300px;
  max-height: 650px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    height: 70vh;
  }
`

export const Title = styled.span`
  font-size: 23px;
  max-width: 328px;
  margin: 0 auto;
  display: block;
  text-align: justify;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    max-width: 680px;
    font-size: 28px;
  }
`
