import React, { useContext, useState, useEffect } from 'react'
import { ThemeContext } from 'styled-components'
import { ImgContainer, TitleContainer, Title } from './styles'

import homeLaptop from '../../../images/home-laptop.jpg'
import homeMobile from '../../../images/home-mobile.jpg'

const HomeImage = () => {
  const { device } = useContext(ThemeContext)
  const [src, setSrc] = useState(null)

  useEffect(() => {
    if (device) {
      const newSrc = device.isMobile ? homeMobile : homeLaptop
      setSrc(newSrc)
    }
  }, [device])

  return (
    <ImgContainer>
      <img
        alt="home"
        src={src}
        style={{
          height: 'calc(100vh - 60px)',
          minHeight: 600,
          width: '100vw',
          maxWidth: 1500,
          objectFit: 'cover',
        }}
      />
      <TitleContainer>
        <Title>
          Découvrez nos produits, confiez-nous vos projets
        </Title>
      </TitleContainer>
    </ImgContainer>
  )
}

export default HomeImage
