import styled, { keyframes } from 'styled-components'

export const ImgContainer = styled.div`
  background-color: #030303;
  position: relative;
  min-height: 600px;
  display: flex;
  justify-content: center;

  > div:first-child {
    max-width: 1500px;
    max-height: 900px;
    min-height: 600px;
    margin: 0 auto;
  }
`

const riseTitle = keyframes`
  from {
    opacity: 0;
    bottom: 20%; // 20px;
  }

  70% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
    bottom: 30%; // 55px;
  }
`

const riseTitleLaptop = keyframes`
  from {
    opacity: 0;
    bottom: 5%; // 20%; // 20px;
  }

  70% {
    opacity: 0.5;
  }

  to {
    opacity: 1;
    bottom: 10%; // 30%; // 65px;
  }
`

export const TitleContainer = styled.div`
  width: 100%;
  position: absolute;
  bottom: 30%; // 55px;
  padding: 0px 15px;
  animation: ${riseTitle} 1s 
    ${({ theme: { animation } }) => animation.func_animation};

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    display: flex;
    justify-content: center;
    bottom: 10%; // 30%; // 65px;
    animation: ${riseTitleLaptop} 1s 
    ${({ theme: { animation } }) => animation.func_animation};
  }
`

export const Title = styled.span`
  display: block;
  font-size: 27px;
  font-weight: 700;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.65);

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    font-size: 35px;
  }
`
