import React from 'react'
import {
  shape, string, bool, arrayOf,
} from 'prop-types'
import Img from 'gatsby-image'

import StyledLink from '../../StyledLink'

import Description from './Description'

import {
  SectionContainer,
  ImgContainer,
  DescriptionCotnainer,
  DescriptionTitle,
  HoverSectionTitle,
  SectionFooter,
  Span,
} from './styles'

const Section = ({
  fixed, kind, reverse, title, btn,
}) => (
  <SectionContainer $reverse={reverse}>
    <ImgContainer>
      <Img fixed={fixed} />
      {title && (<HoverSectionTitle>{title}</HoverSectionTitle>)}
    </ImgContainer>
    <DescriptionCotnainer>
      <div>
        {['music-gear', 'print'].includes(kind) && (
        <DescriptionTitle>
          {title}
        </DescriptionTitle>
        )}
        <Description kind={kind} />
      </div>
      {btn && (
        <SectionFooter>
          {
            btn.map(({ label, to }) => (
              <StyledLink key={label} to={to}>
                <Span>
                  {label}
                </Span>
              </StyledLink>
            ))
          }
        </SectionFooter>
      )}
    </DescriptionCotnainer>
  </SectionContainer>
)

Section.defaultProps = {
  reverse: false,
  title: null,
  btn: null,
}

Section.propTypes = {
  fixed: shape({}).isRequired,
  kind: string.isRequired,
  reverse: bool,
  title: string,
  btn: arrayOf(shape({ label: string.isRequired, to: string.isRequired })),
}

export default Section
