import styled from 'styled-components'

export const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
  padding: 15px;
`

export const SubContainer = styled.div`
  padding-bottom: 80px;
`

export const Title = styled.div`
  color: ${({ theme: { color } }) => color.text};
  font-size: ${({ theme: { size } }) => size.fontSize.xxl};
  text-align: left;
  margin-bottom: 38px;

  border-bottom: 2px solid ${({ theme: { color } }) => color.secondary.main};
  padding-bottom: 10px;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    font-size: ${({ theme: { size } }) => size.fontSize.xxl};
    margin-bottom: 28px;
    text-align: left;
  }
`

export const SubTitle = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.m};
  text-align: left;
  position: relative;
  bottom: -3px;
`

export const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 25px;
  margin-top: 20px;
  margin-bottom: 50px;
  direction: ${({ $reverse }) => $reverse ? 'rtl' : 'ltr'};

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    grid-template-columns: 1fr 1fr;
  }
`

export const ImgContainer = styled.div`
  width: 300px;
  height: 300px;
  position: relative;
  max-width: calc(100vw - 50px);
  overflow: hidden;

  align-self: center;
  justify-self: center;
`

export const DescriptionCotnainer = styled.div`
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  
  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    margin: 0;
  }
`

export const DescriptionTitle = styled.div`
  color: ${({ theme: { color } }) => color.secondary.main};
  font-size: ${({ theme: { size } }) => size.fontSize.xl};
  width: 260px;
  margin: 0 auto 10px;
  font-weight: bold;
  padding: 10px 3px 0px;

  display: none;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    margin: 0 0 10px;;
    width: 300px;
    display: block;
  }
`

export const DescriptionContent = styled.div`
  width: 300px;
  max-width: calc(100vw - 55px);
  margin: 0 auto;
  text-align: justify;
  direction: ltr;

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    margin: 0;
    width: 300px;
  }
`

export const HoverSectionTitle = styled.div`
  position: absolute;
  height: 60px;
  width: 100%;
  top: 125px;
  background-color: rgba(178, 128, 53, 0.7);
  color: ${({ theme: { color } }) => color.text};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme: { size } }) => size.fontSize.xl};

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    visibility: hidden;
  }
`

export const SectionFooter = styled.div`
  width: 300px;
  max-width: calc(100vw - 55px);
  margin: 0 auto;
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Span = styled.span`
  padding: 7px 8px;
  border: 1px solid ${({ theme: { color } }) => color.secondary.main};
  color: ${({ theme: { color } }) => color.secondary.main};
  border-radius: 4px;
  transition: 250ms ${({ theme: { animation } }) => animation.func_animation};
  min-width: 61px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: ${({ theme: { color } }) => color.text_negative};
    background-color: ${({ theme: { color } }) => color.secondary.main};
  }

  @media(min-width: ${({ theme: { breakPoint } }) => breakPoint.tablet}) {
    padding: 8px 13px;
  }
`
