import React from 'react'

import { Container, Title } from './styles'

const HomeTitile = () => (
  <Container>
    <Title>
      Le collectif Old Tree a pour objectif l’accompagnement de groupes
      de musique et artistes en proposant des impressions de merchandising et
      la fabrication de matériel de musique sur mesure.
    </Title>
  </Container>
)

export default HomeTitile
