import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../Layout'
import SEO from '../SEO'

import HomeImage from '../components/Home/HomeImage'
import HomeTitile from '../components/Home/HomeTitile'
import Sections from '../components/Home/Sections'

export const query = graphql`
  {
    file(relativePath: { eq: "seo-home.jpg" }) {
      childImageSharp {
        resize {
          src
          width
          height
        }
      }
    }
  }
`

const IndexPage = ({ data }) => (
  <Layout withPadding={false} restricted={false}>
    <SEO
      title="Home"
      image={data.file.childImageSharp.resize}
    />
    <HomeImage />
    <HomeTitile />
    <Sections />
  </Layout>
)

IndexPage.propTypes = {
  data: shape({}).isRequired,
}

export default IndexPage
