import React from 'react'
import { string } from 'prop-types'

import { DescriptionContent } from './styles'

export const DescriptionShop = () => (
  <DescriptionContent>
    {/* eslint-disable-next-line max-len */}
    Retrouvez ici les articles textiles de la Collection Old Tree Prints, d’artistes que nous affectionnons et avec qui nous travaillons ainsi que des accessoires créés par Old Tree Gear.
  </DescriptionContent>
)

export const DescriptionPrint = () => (
  <DescriptionContent>
    {/* eslint-disable-next-line max-len */}
    Nous imprimons vos visuels sur t-shirts, tote bags, papier et bien plus ! Découvrez toutes nos possibilités d’impression et nos produits et choisissez ceux que vous voulez à l’aide de notre devis en ligne.
  </DescriptionContent>
)
export const DescriptionMusicGear = () => (
  <DescriptionContent>
    {/* eslint-disable-next-line max-len */}
    Nous réalisons, selon vos envies, vos baffles pour guitare ou basse, vos pedalboards ainsi que leur caisse de transport. Choisissez vos options et obtenez votre devis en ligne.
  </DescriptionContent>
)

const Description = ({ kind }) => {
  if (kind === 'shop') {
    return <DescriptionShop />
  }

  if (kind === 'print') {
    return <DescriptionPrint />
  }

  if (kind === 'music-gear') {
    return <DescriptionMusicGear />
  }

  return null
}

Description.propTypes = {
  kind: string.isRequired,
}

export default Description
